import React from "react"
import { Link } from "gatsby"
import grid from '../../scss/flexboxgrid.module.scss'
import view from './feed.module.scss'

export default function InstagramFeed(props) {
  const instagramName = props.data.instagramName
  const instagramPictures = props.data.insta
    
  const renderImages = () => (
    <div className={grid["row"]} style={{ width: "100%", justifyContent: "center" }}>
      {
        instagramPictures.map((item, idx) => (
          <Link className={view["img-wrap"]} key={idx} to={item.link}>
            <img className={view["insta-img"]} src={item.image} alt="Instagram Car Photo" />
          </Link>
        ))
      }
    </div>
  )

  return (
    <div className={view["feed"]}>
      <section className={view["insta-wrap"]}>
        <div className={view["insta-text"]}>
          <b>Our Instagram</b>
        </div>
        <Link className={view["insta-link"]} to={"https://www.instagram.com/" + instagramName + "/"} target="_blank">
          {`@${instagramName}`}
        </Link>
      </section>

      <section className={view["insta-wrap"]} style={{ padding: 0 }}>
        {renderImages()}
      </section>
    </div>
  )
}
